import * as React from "react";
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import Seo from "../../components/seo"


import Projektliste from "../../components/Projekte/Projektliste";
import Siegelliste from "../../components/Projekte/Siegelliste";
import BreadCrumbs from "../../components/BreadCrumbs";
import PageTitle from "../../components/PageHeader/PageTitle";

const breadcrumbs = [
    {name: 'Projekte', href: '/Projekte', current: true},
]

export default function Index({data}) {

    const DelphiProjekte = data.dataYaml.Projekte.filter((projekt) => projekt.Projektart === "Delphi")
    const DotNetProjekte = data.dataYaml.Projekte.filter((projekt) => projekt.Projektart === "DotNet")
    const dotNetCaption = "Die folgenden Projekte habe ich im Bereich C# / .NET umgesetzt."
    const dotNetEinleitung = "Ich habe meinen Fokus auf Projekte im C#/.NET-Bereich gelegt. Dabei\n" +
        " konzentriere ich mich vornehmlich auf die Entwicklung von stabilen und wartbaren Backends. Darüber hinaus entwickle\n" +
        " ich auch Windows Forms Programme. In der jüngeren Vergangenheit ist auch das Thema\n" +
        " Domain-Driven-Design (DDD) immer wichtiger geworden."

    const delphiCaption = "Die folgenden Projekte habe ich mit Delphi umgesetzt."
    const delphiEinleitung = "Mit Delphi können Windows-Anwendungen sehr zügig entwickelt werden. Da das\n" +
        " für viele Kunden sehr attraktiv ist, habe ich mir vor einigen Jahren diese\n" +
        " Kenntnisse angeeignet. Hier sehen Sie, für welche Kunden ich Delphi-Programme umgesetzt habe."

    return (
        <Layout>
            <Seo title="Meine Projekte"/>
            <BreadCrumbs pages={breadcrumbs}/>
            <div className="min-h-full">
                <main className="py-10">

                    <PageTitle HeadlinePart1="Meine" HeadlinePart2="Projekte" Subtitle="Übersicht über meine Entwicklungserfahrung" />

                    <div
                        className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                            <Projektliste titel="C# / .Net-Projekte" caption={dotNetCaption}
                                          einleitung={dotNetEinleitung} projekte={DotNetProjekte}/>

                            <Projektliste titel="Delphi-Projekte" caption={delphiCaption}
                                          einleitung={delphiEinleitung} projekte={DelphiProjekte}/>
                        </div>

                        <Siegelliste/>
                    </div>
                </main>
            </div>
        </Layout>

    )
}

export const query = graphql`
  query ProjekteQuery {
    dataYaml {
        Projekte {
          Pfad
          Endkunde
          Headline1
          Headline2
          Branche
          Aufgabe
          Auftraggeber
          Projektart
        }
      }
  }
`
