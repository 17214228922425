import * as React from "react";
import {StaticImage} from "gatsby-plugin-image";

export default function Siegelliste() {
    return (
        <section aria-labelledby="timeline-title" className="lg:col-start-4 lg:col-span-1">
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" className="text-lg font-medium text-center text-gray-900 mb-6">
                    Zertifizierungen
                </h2>

                <div className="flex flex-col gap-y-4">
                    <StaticImage
                        src="../../images/MCPD.png"
                        formats={["auto", "webp", "avif"]}
                        alt="Microsoft Professional Developer"
                        className="mx-auto"
                    />

                    <StaticImage
                        src="../../images/MCTS.png"
                        formats={["auto", "webp", "avif"]}
                        alt="Microsoft Technical Specialist"
                        className="mx-auto"

                    />
                    <h2 id="timeline-title" className="text-lg font-medium text-center text-gray-900 mt-10 mb-2">
                        Auszeichungen
                    </h2>
                    <StaticImage
                        src="../../images/EtengoSiegel.jpg"
                        formats={["auto", "webp", "avif"]}
                        alt="ETENGO Gold Partner"
                        className="mx-auto"
                    />
                </div>
            </div>
        </section>
    )
}