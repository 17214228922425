import * as React from "react";
import {Link} from 'gatsby'

export default function Projektliste({titel, caption, einleitung, projekte: projekte}) {
    return (
        <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900">
                        {titel}
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{caption}</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-2">
                            <dt className="text-base font-medium text-diit">Einleitung</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {einleitung}
                            </dd>
                        </div>
                        <div className="sm:col-span-2">
                            <dt className="text-base font-medium text-diit mb-2">Projekte</dt>
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div
                                        className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div
                                            className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table
                                                className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Aufgabe / Branche
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                                    >
                                                        Kunde
                                                    </th>


                                                </tr>
                                                </thead>
                                                <tbody
                                                    className="bg-white divide-y divide-gray-200">
                                                {projekte.map((projekt) => (
                                                    <tr key={projekt.Pfad}>
                                                        <td className="px-6 py-4">
                                                            <Link
                                                                className="text-sm font-medium text-blue-600 hover:underline"
                                                                to={projekt.Pfad}>{projekt.Aufgabe}</Link>
                                                            <div
                                                                className="text-sm text-gray-500">{projekt.Branche}</div>
                                                        </td>
                                                        <td className="px-6 py-4 ">
                                                            <div className="flex items-center">

                                                                <div>
                                                                    <div
                                                                        className="text-sm font-medium text-gray-900">{projekt.Endkunde}</div>
                                                                    <div
                                                                        className="text-sm text-gray-500">{projekt.Auftraggeber}</div>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </dl>
                </div>

            </div>
        </section>
    )
}
